<template>
  <div class="d-flex align-center justify-center">
    <v-col cols="12" sm="10" md="6" lg="5">
      <v-card>
        <v-card-title v-if="firma == firma_creada">
          <div
            class="swal-icon swal-icon--success mt-n1"
            v-if="estado_respuesta == 4"
          >
            <span
              class="swal-icon--success__line swal-icon--success__line--long"
            ></span>
            <span
              class="swal-icon--success__line swal-icon--success__line--tip"
            ></span>
            <div class="swal-icon--success__ring"></div>
            <div class="swal-icon--success__hide-corners"></div>
          </div>
          <v-col cols="12" class="d-flex justify-center mt-n2" v-else>
            <div class="f-modal-alert">
              <div class="f-modal-icon f-modal-warning scaleWarning">
                <span class="f-modal-body pulseWarningIns"></span>
                <span class="f-modal-dot pulseWarningIns"></span>
              </div>
            </div>
          </v-col>
        </v-card-title>
        <div class="mt-n4" v-if="firma == firma_creada">
          <h1
            style="text-align: center"
            class="text-lg-h3 text-md-h3 text-sm-h3 text-h5"
          >
            Red Médica Vital
          </h1>
          <h2
            style="text-align: center"
            class="text-lg-h4 text-md-h4 text-sm-h4 text-h6"
          >
            Resumen Transacción
          </h2>
        </div>

        <v-card-text style="text-align: center" v-if="firma == firma_creada">
          <v-divider class="mb-5"></v-divider>

          <h2>Estado de la transaccion</h2>
          <h3>{{ estado_respuesta_texto }}</h3>
          <h2>ID de la transaccion</h2>
          <h3>{{ id_transaction }}</h3>
          <h2>Referencia de la venta</h2>
          <h3>{{ reference_pol }}</h3>
          <h2>Referencia de la transaccion</h2>
          <h3>{{ reference_code }}</h3>
          <div v-if="banco != ''">
            <h2>Cus</h2>
            <h3>{{ cus }}</h3>
            <h2>Banco</h2>
            <h3>{{ banco }}</h3>
          </div>
          <h2>Valor total</h2>
          <h3>${{ total | moneda }}</h3>
          <h2>Moneda</h2>
          <h3>{{ tipo_moneda }}</h3>
          <h2>Descripción</h2>
          <h3>{{ extra1 }}</h3>
          <h2>Entidad</h2>
          <h3>{{ lap_payment_method }}</h3>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-text v-if="firma != firma_creada" style="text-align: center">
          <h3>Error al validar la firma</h3>
        </v-card-text>
        <v-card-actions class="d-flex justify-center mt-n2 mb-1">
          <v-btn color="primary" class="mr-4" v-if="firma == firma_creada">
            Descargar
          </v-btn>
          <v-btn color="success" @click="$router.push({ path: '/' })">
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import { Md5 } from "ts-md5";
import configsService from "../general/services/configsService";
export default {
  data: () => ({
    estado_respuesta: 4,
    estado_respuesta_texto: "",
    merchant_id: "",
    reference_code: "",
    total: 0,
    tipo_moneda: "COP",
    estado_transaccion: "",
    firma_cadena: "",
    firma_creada: "",
    firma: "",
    reference_pol: "",
    cus: "",
    extra1: "",
    banco: "",
    lap_payment_method: "",
    id_transaction: "",
  }),
  async created() {
    //Se activa el icono de carga de la pantalla
    this.$store.state.loading = true;
    this.estado_respuesta = parseInt(this.$route.query.transactionState);

    switch (this.estado_respuesta) {
      case 4:
        this.estado_respuesta_texto = "Transacción Aprobada";
        break;
      case 6:
        this.estado_respuesta_texto = "Transacción Rechazada";
        break;
      case 7:
        this.estado_respuesta_texto = "Transacción Pendiente";
        break;
      case 104:
        this.estado_respuesta_texto = "Error en Transaccion";
        break;
      default:
        this.estado_respuesta_texto = this.$route.query.mensaje;
        break;
    }
    this.merchant_id = this.$route.query.merchantId;
    this.reference_code = this.$route.query.referenceCode;
    this.total = parseInt(this.$route.query.TX_VALUE);
    var New_value = this.number_format(this.$route.query.TX_VALUE, 1, ".", "");
    this.tipo_moneda = this.$route.query.currency;
    var api_key;
    await configsService.obtenerPasarelaPagoActiva().then((response) => {
      api_key = response.data[0].api_key;
    });
    this.firma_cadena =
      api_key +
      "~" +
      this.merchant_id +
      "~" +
      this.reference_code +
      "~" +
      New_value +
      "~" +
      this.tipo_moneda +
      "~" +
      this.estado_respuesta;

    const md5 = new Md5();
    this.firma_creada = md5.appendStr(this.firma_cadena).end();

    this.firma = this.$route.query.signature;
    this.reference_pol = this.$route.query.reference_pol;
    this.cus = this.$route.query.cus;
    this.extra1 = this.$route.query.description;
    this.banco = this.$route.query.pseBank;
    this.lap_payment_method = this.$route.query.lapPaymentMethod;
    this.id_transaction = this.$route.query.transactionId;

    console.log(this.firma);
    console.log(this.firma_creada);

    //Se activa el icono de carga de la pantalla
    this.$store.state.loading = false;
  },
  methods: {
    /**
     * Este método es una versión en Javascript del método de PHP number_format
     * para poder dar formato correctamente a los valores de payu
     *
     * https://www.php.net/manual/es/function.number-format.php
     *
     * Éste método fue entontrado en:
     * https://stackoverflow.com/questions/12820312/equivalent-to-php-function-number-format-in-jquery-javascript
     * que a su vez lo copió de aquí:
     * https://locutus.io/php/strings/number_format/
     *
     * @param number Valor float a ser formateado
     * @param decimals número de decimales que queremos en el resultado
     * @param dec_point Establece el separador para los decimales.
     * @param thousands_sep Establece el separador para los millares.
     */
    number_format(number, decimals, dec_point, thousands_sep) {
      // Strip all characters but numerical ones.
      number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
      var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
        dec = typeof dec_point === "undefined" ? "." : dec_point,
        s = "",
        toFixedFix = function (n, prec) {
          var k = Math.pow(10, prec);
          return "" + Math.round(n * k) / k;
        };
      s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
      }
      if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
      }
      return s.join(dec);
    },
  },
};
</script>
<style scoped>
h3 {
  padding: 10px;
  font-weight: normal;
}
.swal-icon--success {
  border-color: #a5dc86;
}

.swal-icon--success:after,
.swal-icon--success:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;

  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.swal-icon--success:before {
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
}

.swal-icon--success:after {
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 60px;
  transform-origin: 0 60px;
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in;
}

.swal-icon--success__ring {
  width: 80px;
  height: 80px;
  border: 4px solid hsla(98, 55%, 69%, 0.2);
  border-radius: 50%;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2;
}

.swal-icon--success__hide-corners {
  width: 5px;
  height: 90px;
  background-color: #fff;
  padding: 1px;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.swal-icon--success__line {
  height: 5px;
  background-color: #a5dc86;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
}

.swal-icon--success__line--tip {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s;
}

.swal-icon--success__line--long {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s;
}

.swal-icon {
  width: 80px;
  height: 80px;
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;
  padding: 0;
  position: relative;
  box-sizing: content-box;
  margin: 20px auto;
}

.swal-icon:first-child {
  margin-top: 32px;
}

@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  to {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  to {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  to {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
</style>
<style lang="scss" scoped>
.f-modal-alert {
  border-radius: 4px;
}

.f-modal-alert .f-modal-icon {
  border-radius: 50%;
  border: 4px solid gray;
  box-sizing: content-box;
  height: 80px;

  padding: 0;
  position: relative;
  width: 80px;

  // Success icon
  &.f-modal-success,
  &.f-modal-error {
    border-color: #a5dc86;

    &:after,
    &:before {
      background: #fff;
      content: "";
      height: 120px;
      position: absolute;
      transform: rotate(45deg);
      width: 60px;
    }

    &:before {
      border-radius: 120px 0 0 120px;
      left: -33px;
      top: -7px;
      transform-origin: 60px 60px;
      transform: rotate(-45deg);
    }

    &:after {
      border-radius: 0 120px 120px 0;
      left: 30px;
      top: -11px;
      transform-origin: 0 60px;
      transform: rotate(-45deg);
    }

    .f-modal-placeholder {
      border-radius: 50%;
      border: 4px solid rgba(165, 220, 134, 0.2);
      box-sizing: content-box;
      height: 80px;
      left: -4px;
      position: absolute;
      top: -4px;
      width: 80px;
      z-index: 2;
    }

    .f-modal-fix {
      background-color: #fff;
      height: 90px;
      left: 28px;
      position: absolute;
      top: 8px;
      transform: rotate(-45deg);
      width: 5px;
      z-index: 1;
    }

    .f-modal-line {
      background-color: #a5dc86;
      border-radius: 2px;
      display: block;
      height: 5px;
      position: absolute;
      z-index: 2;

      &.f-modal-tip {
        left: 14px;
        top: 46px;
        transform: rotate(45deg);
        width: 25px;
      }

      &.f-modal-long {
        right: 8px;
        top: 38px;
        transform: rotate(-45deg);
        width: 47px;
      }
    }
  }

  // Error icon
  &.f-modal-error {
    border-color: #f27474;

    .f-modal-x-mark {
      display: block;
      position: relative;
      z-index: 2;
    }

    .f-modal-placeholder {
      border: 4px solid rgba(200, 0, 0, 0.2);
    }

    .f-modal-line {
      background-color: #f27474;
      top: 37px;
      width: 47px;

      &.f-modal-left {
        left: 17px;
        transform: rotate(45deg);
      }

      &.f-modal-right {
        right: 16px;
        transform: rotate(-45deg);
      }
    }
  }

  // Warning icon

  &.f-modal-warning {
    border-color: #f8bb86;

    &:before {
      animation: pulseWarning 2s linear infinite;
      background-color: #fff;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 100%;
      opacity: 0;
      position: absolute;
      width: 100%;
    }

    &:after {
      background-color: #fff;
      border-radius: 50%;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }

  &.f-modal-warning .f-modal-body {
    background-color: #f8bb86;
    border-radius: 2px;
    height: 47px;
    left: 50%;
    margin-left: -2px;
    position: absolute;
    top: 10px;
    width: 5px;
    z-index: 2;
  }

  &.f-modal-warning .f-modal-dot {
    background-color: #f8bb86;
    border-radius: 50%;
    bottom: 10px;
    height: 7px;
    left: 50%;
    margin-left: -3px;
    position: absolute;
    width: 7px;
    z-index: 2;
  }

  + .f-modal-icon {
    margin-top: 50px;
  }
}

.animateSuccessTip {
  animation: animateSuccessTip 0.75s;
}

.animateSuccessLong {
  animation: animateSuccessLong 0.75s;
}

.f-modal-icon.f-modal-success.animate:after {
  animation: rotatePlaceholder 4.25s ease-in;
}

.f-modal-icon.f-modal-error.animate:after {
  animation: rotatePlaceholder 4.25s ease-in;
}

.animateErrorIcon {
  animation: animateErrorIcon 0.5s;
}

.animateXLeft {
  animation: animateXLeft 0.75s;
}

.animateXRight {
  animation: animateXRight 0.75s;
}

.scaleWarning {
  animation: scaleWarning 0.75s infinite alternate;
}

.pulseWarningIns {
  animation: pulseWarningIns 0.75s infinite alternate;
}

@keyframes animateSuccessTip {
  0%,
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes animateSuccessLong {
  0%,
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@keyframes rotatePlaceholder {
  0%,
  5% {
    transform: rotate(-45deg);
  }

  100%,
  12% {
    transform: rotate(-405deg);
  }
}

@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }

  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

@keyframes animateXLeft {
  0%,
  65% {
    left: 82px;
    top: 95px;
    width: 0;
  }

  84% {
    left: 14px;
    top: 33px;
    width: 47px;
  }

  100% {
    left: 17px;
    top: 37px;
    width: 47px;
  }
}

@keyframes animateXRight {
  0%,
  65% {
    right: 82px;
    top: 95px;
    width: 0;
  }

  84% {
    right: 14px;
    top: 33px;
    width: 47px;
  }

  100% {
    right: 16px;
    top: 37px;
    width: 47px;
  }
}

@keyframes scaleWarning {
  0% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pulseWarning {
  0% {
    background-color: #fff;
    transform: scale(1);
    opacity: 0.5;
  }

  30% {
    background-color: #fff;
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    background-color: #f8bb86;
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulseWarningIns {
  0% {
    background-color: #f8d486;
  }

  100% {
    background-color: #f8bb86;
  }
}
</style>
